<template>
  <b-overlay :show="loading">
    <form-component
      v-if="!loading"
      :show-cancel-button="false"
      :model="accountType"
    />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import AccountType from '@/dc-it/models/AccountType'
import FormComponent from '@/dc-it/components/FormComponent.vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'SavingAccountConfigView',
  components: {
    FormComponent,
    BOverlay,
  },
  setup() {
    const loading = ref(false)
    const accountType = ref(null)

    const loadAccountType = async () => {
      loading.value = true
      const accountTypes = await AccountType.where('type', '=', 1).get()
      if (accountTypes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        accountType.value = accountTypes[0]
      }
      loading.value = false
    }

    const saveAccountType = async () => {
      loading.value = true
      await accountType.value.save()
      loading.value = false
    }

    loadAccountType()

    return {
      loading,
      accountType,
      loadAccountType,
      saveAccountType,
    }
  },
}
</script>

<style scoped>

</style>
